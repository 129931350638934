import React from "react";
import { Route, Routes } from "react-router";
import Home from "./pages/home";
import Leadership from "./pages/leadership";
import Integrity from "./pages/Integrity";
import Partnership from "./pages/Partnership";
import Excellence from "./pages/Excellence";
import Professionalism from "./pages/Professionalism";
import Login from "./pages/login";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/welcome" element={<Home />} />
      <Route path="/leadership" element={<Leadership />} />
      <Route path="/integrity" element={<Integrity />} />
      <Route path="/professionalism" element={<Professionalism />} />
      <Route path="/partnership" element={<Partnership />} />
      <Route path="/excellence" element={<Excellence />} />
      
     
    </Routes>
  );
}

export default App;
