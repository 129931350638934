import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { motion } from "framer-motion"; // Importing framer-motion for animations
import Navbar from "../components/Navbar";
import { Link } from "react-router";

function Home() {
  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center justify-center p-8">
      <div className="bg-white shadow-lg rounded-lg w-full max-w-3xl p-6">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-4">
          Employee Awards 2024: Recognition of Excellence
        </h1>
        <p className="text-lg text-gray-600 mb-6 text-center">Dear Team,</p>
        <p className="text-lg text-gray-600 mb-6 text-center">
          As we approach the conclusion of another remarkable year, we are
          excited to recognise the exceptional contributions of our staff
          through the 2024 Employee Awards. These awards celebrate individual
          achievements, dedication, and the values that drive our success as an
          organisation.
        </p>

        <div className="bg-blue-50 p-4 rounded-lg mb-6">
          <h2 className="text-xl font-semibold text-blue-800 mb-2">
            Award Categories:
          </h2>
          <ul className="list-disc list-inside text-gray-600">
            <li>
              Leadership Award – Honoring outstanding leadership and
              collaboration.
            </li>
            <li>
              Integrity Award – Celebrating adherence to ethical standards and
              honesty.
            </li>
            <li>
              Proficiency Award – Recognizing exceptional skills and expertise
              in roles.
            </li>
            <li>
              Partnership Award – Appreciating impactful collaboration with
              stakeholders.
            </li>
            <li>
              Excellence Award – Acknowledging consistent, top-tier performance.
            </li>
          </ul>
        </div>
        <p className="text-lg text-gray-600 text-center mb-6">
          To ensure fairness, staff members will not evaluate themselves during
          this exercise. The highest scorer across all categories will receive
          the prestigious Employee of the Year Award 2024.
        </p>

        <p className="text-lg text-gray-600 text-center mb-6">
          Please complete the evaluation form, considering the scoring
          dimensions provided in the matrix. Let’s take this opportunity to
          highlight and celebrate our team's excellence.
        </p>
        
        <h2 className="text-xl font-semibold text-blue-800 mb-2">
        Overall Rating Scale for Each Criterion:
          </h2>
          <ul className="list-disc list-inside text-gray-600">
          <li>0. I do not know</li>
          <li>1.  Needs Improvement: Rarely meets expectations in this area.</li>
          <li>2 Fair: Occasionally meets expectations; some improvement needed.</li>
          <li>3. Good: Consistently meets expectations.</li>
         <li>4. Very Good: Frequently exceeds expectations.</li>
           <li>5.  Outstanding: Consistently goes above and beyond expectations</li>
          </ul>
          
    
        
        <div className="flex justify-center mt-8">
          <Link to="/leadership">
            <button className="bg-blue-600 text-white font-semibold py-2 px-6 rounded-lg hover:bg-blue-700 transition duration-300">
              Complete Evaluation Form
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Home;
