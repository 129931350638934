import React, { useState, useEffect } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode"; // Install with npm install jwt-decode
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

const Partnership = () => {
  const  allEmployees = [
    "Abudlah Chambaga",
"Andrew Arthur Agaba",
"Brendah Kababwiju",
"Christine Birungi",
"Goretti Masadde",
"Ibrahim Gava Kalule",
"John Bosco Habere",
"John Walusimbi",
"Jude Mufuumula",
"Kelvin Abaasa",
"Lawrence Tebandeke",
"Mary Florence Kayaga",
"Mary Kansiime",
"Mudasiru Tamuzadde",
"Pamela Nakintu",
"Peter Kabaseke Musinguzi",
"Priscilla Mwesige",
"Priscillar Tumusiime",
"Richard Semakula",
"Ronald Mugwanya",
"Sam Damba",
"Warren Mwesigye",

  ];

  const [scores, setScores] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const navigate = useNavigate()
  const location = useLocation();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      const loggedInUserName = decodedToken?.name; // Ensure the token contains 'name'
      const filteredList = allEmployees.filter(
        (employee) => employee !== loggedInUserName
      );
      setFilteredEmployees(filteredList);

      // Initialize scores for filtered employees
      setScores(
        filteredList.map((name) => ({
          name,
          attributes: Array(5).fill(0), // 5 attributes initialized to 0
        }))
      );
    }
  }, []);

  const handleChange = (index, attributeIndex, value) => {
    setScores((prevScores) => {
      const updatedScores = [...prevScores];
      updatedScores[index].attributes[attributeIndex] = parseInt(value) || 0;
      return updatedScores;
    });
  };

  const calculateTotal = (attributes) => attributes.reduce((a, b) => a + b, 0);

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");
  
    if (!token) {
      toast.error("You must be logged in to submit scores.");
      return;
    }
  
    // Check if at least one employee has been nominated
    const hasNominations = scores.some(({ attributes }) =>
      attributes.some(value => value > 0)
    );
  
    if (!hasNominations) {
      toast.error("Please nominate at least one employee before submitting.");
      return;
    }
  
    const dataToSend = scores.map(({ name, attributes }) => ({
      name,
      total: calculateTotal(attributes),
    }));
  
    setIsLoading(true);
  
    try {
      const response = await axios.post(
        "https://employeebackedsm2024.onrender.com/api/pertnership/submit-scores",
        { data: dataToSend },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Scores submitted successfully!");

      navigate("/excellence")

    } catch (error) {
      if (error.response) {
        console.error("Server error:", error.response.data);
        toast.error(error.response.data.error);
      } else {
        console.error("Network error:", error.message);
        toast.error("An error occurred. Please check your network and try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };
  
  const renderDropdown = (index, attributeIndex) => (
    <select
      defaultValue=""
      className="border border-gray-300 rounded px-2 py-1 focus:ring-blue-500 focus:border-blue-500"
      onChange={(e) => handleChange(index, attributeIndex, e.target.value)}
    >
      <option value="" disabled>
        Select...
      </option>
      {[0,1, 2, 3, 4, 5].map((value) => (
        <option key={value} value={value}>
          {value}
        </option>
      ))}
    </select>
  );
  useEffect(() => {
    console.log("Navigated to:", location.pathname);
    // Add any logic to reload or fetch data here
  }, [location]);


  return (
    <>
      <Navbar />
      <ToastContainer />
      <div className="container mx-auto py-6 px-4">
        <h1 className="text-2xl font-bold mb-4 text-center">
        Score each employee in Partnership
        </h1>
        <h3 className="text-sm  font-bold mb-4 text-center">
          How has the employee demonstrated collaboration and teamwork, built strong relationships, shown commitment to mutual goals, excelled in problem-solving, and practiced effective communication to strengthen partnerships and drive organizational success?</h3>
        <div className="overflow-x-auto">
          <table className="table-auto w-full border border-gray-200 shadow-md">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-4 py-2 text-left font-medium text-gray-600">#</th>
                <th className="px-4 py-2 text-left font-medium text-gray-600">
                  Employee Name
                </th>
                <th className="px-4 py-2 text-left font-medium text-gray-600">
                  Collaboration and teamwork
                </th>
                <th className="px-4 py-2 text-left font-medium text-gray-600">
                  Building strong relationships
                </th>
                <th className="px-4 py-2 text-left font-medium text-gray-600">
                  Commitment to mutual goals
                </th>
                <th className="px-4 py-2 text-left font-medium text-gray-600">
                  Problem solving
                </th>
                <th className="px-4 py-2 text-left font-medium text-gray-600">
                  Effective communication
                </th>
              </tr>
            </thead>
            <tbody>
              {scores.map(({ name, attributes }, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-white" : "bg-gray-50"
                  } hover:bg-blue-50`}
                >
                  <td className="px-4 py-2 border">{index + 1}</td>
                  <td className="px-4 py-2 border">{name}</td>
                  {attributes.map((_, attributeIndex) => (
                    <td
                      key={attributeIndex}
                      className="px-4 py-2 border text-center"
                    >
                      {renderDropdown(index, attributeIndex)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-4 text-center">
            <button
              onClick={handleSubmit}
              disabled={isLoading}
              className={`${
                isLoading
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              } text-white px-4 py-2 rounded`}
            >
              {isLoading ? "Submitting..." : "Submit Scores"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Partnership;
