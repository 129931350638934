import { Link } from "react-router";

const Navbar = () => {
    return (
      <nav className="bg-gray-800 text-white p-4 flex justify-between items-center">
        <h1 className="text-2xl font-bold">UIBFS Awards</h1>
        <div className="space-x-4">


          <Link to="/" ><button className="hover:text-gray-400">Logout</button></Link>
          
        </div>
      </nav>
    );
  };
  
  export default Navbar;
  